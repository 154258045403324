// import Background from "../assets/bg.jpg";

const HeroSection = () => {
  return (
    <article className="hero">
      <div className="h-screen relative main-page">
        <div className="max-w-[1280px] mx-auto px-8 md:px-0">
          <p className="pt-40">
            <img
              src="/imgs/JFINChain-logo.svg"
              className="w-48"
              alt="jfin_chain"
            />
          </p>
          <h1 className="text-3xl md:text-5xl lg:text-8xl font-bold uppercase pt-4">
            Beyond <br />
            the Future
          </h1>

          <ul className="detail grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8 mt-[200px] text-center mx-auto lg:w-5/6">
            <li>
              <div className="bg-SL100/5 md:h-[240px]">
                <h3 className="text-2xl font-bold text-SL100/75">
                  Transaction
                </h3>
                <p className="text-SL100/75">Pay for transaction fees.</p>
              </div>
            </li>
            <li>
              <div className="bg-SL100/5 md:h-[240px]">
                <h3 className="text-2xl font-bold text-SL100/75">Staking</h3>
                <p className="text-SL100/75">
                  Staking on JFIN Chain allows everyone to earn rewards.
                </p>
              </div>
            </li>
            <li>
              <div className="bg-SL100/5 md:h-[240px]">
                <h3 className="text-2xl font-bold text-SL100/75">Governance</h3>
                <p className="text-SL100/75">
                  Participate in decentralized protocol governance.
                </p>
              </div>
            </li>
          </ul> 
        </div>
      </div>
    </article>
  );
};

export default HeroSection;
